/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useCallback, useEffect } from 'react'
import Cookies from 'universal-cookie';
import { SelectSucursal } from '../../components/Selects/SelectsPrincipales'
import { notify_warning, notify_valido, selectTabla, selectTablaMultiple, removeSelectedRows } from '../../components/utils';
import $, { map } from 'jquery'
import '../../App.css';
import { ToastContainer } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { Box, Modal, Tooltip } from '@mui/material';
import AsyncSelect from 'react-select/async';
import DatePicker from "react-datepicker";
import { AButton } from '../../components/Button';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { saveAs } from 'file-saver';

const moment = require('moment')
const cookies = new Cookies();
const cook_idEmp = cookies.get('dtEmp_id')
const globalTheme = window.templateCustomizer.settings.style;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const styles = {

  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? null : null,
    backgroundColor: globalTheme === 'light'
      ? '#FEFFF4'
      : '#2B2C40',
    color: globalTheme === 'light'
      ? '#2B2C40'
      : '#FEFFF4',
    minHeight: `29px`,
    height: `29px`,
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40')
      : (globalTheme === 'light' ? '#FEFFF4' : '#2B2C40'),
    color: state.isSelected
      ? '#FF3E1D'
      : (globalTheme === 'light' ? '#2B2C40' : '#FEFFF4'),
    '&:hover': {
      backgroundColor: '#FF3E1D',
      color: 'white',
    },
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    backgroundColor: globalTheme === 'light' ? '#fefff4' : '#2B2C40',
    color: globalTheme === 'light' ? '#2B2C40' : '#fefff4',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
    padding: `0 6px`,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
  }),
  input: (provided, state) => ({
    ...provided,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
    fontSize: `0.75rem`,
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: `29px`,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: globalTheme === 'light' ? '#2B2C40' : '#FEFFF4',
  }),
};

// Reporte de finanzas 
const TablaPersona = (prosp) => {
  const selectFila = prosp.selectFila
  const [data,setdata]= useState([])
  const funListarPersonas = async (cadena) => {

    try {
      const response = await fetch(`/personasRepFinanzCredi?vcodEmpresa=${cook_idEmp}&cadenaBuscar=${cadena}`, { method: "GET", headers: { 'Content-Type': 'application/json' } });
      if (!response.ok) throw new Error(`Error al obtener los datos. Código de estado: ${response.status}`);
      const data = await response.json();
      console.log("--:: ",data[0]);
      setdata(data);
    } catch (error) {
      notify_warning('Sin datos registrados');
      console.error('Error:', error);
      // Manejar el error aquí según sea necesario.
    } finally {
    }
  }

  const[cadenaBuscar, setCadenabuscar] = useState('');
  const onChangeBuscar = (ev)=>{
    const cadena =  (ev.target.value).toUpperCase()
    setCadenabuscar(cadena);
    
  }
  const onClickBuscar = async () => {
    console.log("cadena a buscar :",cadenaBuscar  );
    funListarPersonas(cadenaBuscar)
  }
  useEffect(()=>{
     funListarPersonas(cadenaBuscar)
  },[])

  const FilaTabla = (prosp) => {
    const data=prosp.datoRow
    return (
          <>
              <tr key={data.CODIGO} >
                <td>
                  <AButton bt_funct={(e) => { selectFila(data)} } tp_tilde="Seleccionar" tp_posit="left" bt_class="btn btn-sm btn-icon btn-label-danger" ic_class="bx bx-select-multiple" vl_tpbtn={1} />
                </td>
                <td >
                  {data.CODIGO}
                </td>
                <td>{data.NOMBRE}</td>
                <td> {data.AREA}</td>
              </tr>
          </>
    )
  }

  return(
    <div>
      <div className="col-6">
      <label>Bucar </label>

      <div className='input-group'>
      <input className='form-control form-control-sm mr-2' type="text" value={cadenaBuscar}  onChange={onChangeBuscar}/>
        <button className='btn btn-sm btn-icon btn-label-info ml-3' onClick={onClickBuscar} > 
          <i className='bx bx-search-alt-2'></i>
        </button>
      </div>
      </div>
      <div className="data-tables table-responsive text-nowrap mt-3 " style={{ position: 'relative', zIndex: '1', width: 'auto', height: '400px', overflowY: 'scroll', overflowX: 'scroll' }}>
        <table className="table table-sm"  style={{ fontSize: '10px', zIndex: '0', }}>
          <thead className='' style={{ position: 'sticky', top: 0, zIndex: 10 }}>
            <tr >
              <th>Actions</th>
              <th style={{ width: '250px' }} >Codigo </th>
              <th style={{ width: '250px' }} > Nombre  </th>
              <th style={{ width: '650px' }} >Area</th>
              {/* <th>Opciones</th> */}
            </tr>
          </thead>
          <tbody >
            {
              (data.length)? data.map( row =>(
                // console.log("dsds");
                  <React.Fragment key={row.CODIGO}>
                    <FilaTabla key={row.CODIGO} datoRow={row} />
                  </React.Fragment>
              )):<div></div>
            }
          </tbody>
        </table>
      </div>


    </div>
  )
}

const TablaCuenta = (prosp) => {
  const selecionaFila= prosp.selecionaFila;
  const [data,setdata]= useState([])
  const [pendingModal, setpendingModal] = useState(false);
  const funListarcuentas = async () => {

    try {
      setpendingModal(true);
      const response = await fetch(`/cuentasRepFinanzCredi?vcodEmpresa=${cook_idEmp}`, { method: "GET", headers: { 'Content-Type': 'application/json' } });
      if (!response.ok) throw new Error(`Error al obtener los datos. Código de estado: ${response.status}`);
      const data = await response.json();
      console.log("--:: ",data[0]);
      setdata(data);
    } catch (error) {
      notify_warning('Sin datos registrados');
      console.error('Error:', error);
      // Manejar el error aquí según sea necesario.
    } finally {
      setpendingModal(false);
    }
  }

  const columnsModal = useMemo(() => [
    { header: 'Codigo', accessorKey: 'Codigo' },
    { header: 'Nombre', accessorKey: 'Nombre' },

  ], [])
  useEffect(()=>{
    funListarcuentas()
  },[])
return (
  <>
    <div className="" >
            <p>Cuentas </p>
            <MaterialReactTable
              columns={columnsModal}
              data={data}
              state={{ isLoading: pendingModal }}
              initialState={{ density: 'compact', showColumnFilters: false, showGlobalFilter: false, }}
              enableGrouping
              enableStickyHeader enableColumnResizing
              muiToolbarAlertBannerChipProps={{ color: '' }}
              localization={MRT_Localization_ES} primary
              enablePagination={true}
              muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
              enableGlobalFilterModes
              rowVirtualizerProps={{ overscan: 8 }}
              enableRowVirtualization
              muiTableContainerProps={{ sx: { maxHeight: 400 } }}
              //enableEditing

              displayColumnDefOptions={{
                'mrt-row-actions': {
                  header: 'Options', //change header text
                  size: 100, //make actions column wider
                },
              }}
              // onRowDoubleClick={selecionaFila}
              muiTableBodyRowProps={({ row }) => ({
                hover: false,

                onMouseDown: (event) => selecionaFila(event, row), // El  presiona el mouse 

              })}
              muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}


            />
          </div>
  </>
)
}
const SelectVendedor = (prosp) => {
  const setSelectFila = prosp.setSelectFila
  const fila = prosp.fila


  const funListar = useCallback(async (nom) => {
    //console.log("cadena:", cadena);
    let  cadena= nom? nom.toUpperCase():"";
    const url = `/getVendedorRepor?vcodEmpresa=${cook_idEmp}&cadenaBuscar=${cadena}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };

    try {

      const response = await fetch(url, opPe);
      if (!response.ok) {
        throw new Error(`Error al obtener los datos. Código de estado: ${response.status}`);
        return[];
      }
      const data = response.json();
      // console.log("data :", data);
      return data;
    } catch (error) {
      console.error("Error en funListar:", error);
      return [];
      throw error;
    }
  }, [])

  const handleSelectChange = (selectedOption) => {
    
    setSelectFila(selectedOption)
  };

  return (
    <div className="col-12">
      <div className='td2' style={{ position: 'relative', zIndex: '3' }} >
            <AsyncSelect
              styles={styles}
              placeholder="Busque por Cod./Desc."
              name="form-field-name"
              className=" from-control form-control-sm"
              value={fila}
              cacheOptions
              defaultOptions
              getOptionLabel={e => e['Codigo Vendedor'] + '  -  ' + e['Nombre Vendedor']}
              getOptionValue={e => e['Codigo Vendedor']}
              loadOptions={funListar}
              onChange={handleSelectChange}
              maxMenuHeight={150}
            />
        </div>
    </div>
    
  )
}
const SelectPersona = (prosp) => {
  const setSelectFila = prosp.setSelectFila
  const fila = prosp.fila


  const funListar = useCallback(async (nom) => {
    //console.log("cadena:", cadena);
    let  cadena= nom? nom.toUpperCase():"";
    const url = `/personasRepFinanzCredi?vcodEmpresa=${cook_idEmp}&cadenaBuscar=${cadena}`;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };

    try {

      const response = await fetch(url, opPe);
      if (!response.ok) {
        throw new Error(`Error al obtener los datos. Código de estado: ${response.status}`);
        return[];
      }
      const data = await response.json();
      console.log("data :", data);
      return data;
    } catch (error) {
      console.error("Error en funListar:", error);
      return [];
      throw error;
    }
  }, [])

  const handleSelectChange = (selectedOption) => {
    
    setSelectFila(selectedOption)
  };

  return (
    <div className="col-12">
      <div className='td2' style={{ position: 'relative', zIndex: '2' }} >
            <AsyncSelect
              styles={styles}
              placeholder="Busque por Cod./Desc."
              name="form-field-name"
              className=" from-control form-control-sm"
              value={fila}
              cacheOptions
              defaultOptions
              getOptionLabel={e => e['CODIGO'] + '  -  ' + e['NOMBRE']}
              getOptionValue={e => e['CODIGO']}
              loadOptions={funListar}
              onChange={handleSelectChange}
              maxMenuHeight={150}
            />
        </div>
    </div>
    
  )
}

export const Report1 = (prosp) => { //  FIN002 :  CUENTAS POR COBRAR Y PAGAR POR PERSONA 
  const { dataTipo } = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');
  // variables 
  const [text_fechaD,    setText_fechaD] = useState(new Date());

  const [emp_sl_t_suc,        emp_setSl_t_suc] = useState('');
  const emp_changeSl_t_suc = (event) => { 
    emp_setSl_t_suc(event.target.value);
    // $("#emp_slc_tem").focus();
  }
 
  const [dataReport, setdataReport] = useState([]);
  const [pending, setPending] = useState(false);
  const [openModal, setopenModal] = useState(false);
  const [openModalPersona, setopenModalPersona] = useState(false);

  

  // MODAL PERSONA
  const cerrarModalPersona = () => {
    setopenModalPersona(false);
  }
  const opnenModalPersona = () => {
    setopenModalPersona(true);
  }
  const[ persona, setpersona]=useState({
    codigo:"",
    nombre:""
  });
  

  const selectFilaPersona = (element)=>{
    // console.log(" elec finala ",cod);
    setpersona({
      nombre:element.NOMBRE,
      codigo:element.CODIGO
    })
    cerrarModalPersona()
  }


  //** MODAL CUENTAS  */
  const [dataCuentas, setdataCuentas] = useState({
    nombre:"",
    codigo:""
  });

  const handleOpen = () => {
    setopenModal(true);
    // funListarcuentas();

  };
  const cerrarModalPer = () => {
    setopenModal(false);
  }
 
  const selecionaFila = (e,rowData) => {
    console.log("dde");
    const data = rowData.original;
    // console.log(data);
    setdataCuentas({
     nombre:data.Nombre,
     codigo:data.Codigo
    });
    setopenModal(false)

  }
  


  //*** SELECT  */

  const [cobrarPagar, setcobrarPagar] = useState('');
  const [contadoCredito, setcontadoCredito] = useState('');

  const  onChangecobrarPagar= (e)=>{
    setcobrarPagar(e.target.value);
  }
  const  onChangecontadoCredito= (e)=>{
    setcontadoCredito(e.target.value);
  }


  /** Primer reporte */

  



  const onClickListar = async () => {

    setdataReport([])
    setPending(true)
    const url_dataReport = `/apiRepFinanzasCreditos?`;
    const body = {
                  vTipoReport: dataTipo,
                  vcodEmpresa:   cook_idEmp,
                  sucursal:      emp_sl_t_suc=='9999'?'':emp_sl_t_suc,
                  contadoCredito:contadoCredito,
                  cuenta:        dataCuentas.codigo,
                  cobrarPagar:   cobrarPagar,
                  fecha:         moment(text_fechaD).format('YYYYMMDD'),
                  persona:       selectPersona['CODIGO'] 
      };

      console.log("body: ", body);

    const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    const response = await fetch(url_dataReport, opPe);
    const data = await response.json();
    // console.log("data :", data);
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    setcobrarPagar('')
    setcontadoCredito('')
    setText_fechaD(new Date())
    setdataCuentas({
      codigo:"",nombre:""
    })
    // setpersona({
    //   codigo:"",nombre:""
    // })
    setselectPersona({
      "CODIGO":"",
      "NOMBRE":""
    })
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "REPORTE-CUENTAS POR COBRAR Y PAGAR POR PERSONA_"+moment(new Date()).format('YYYYMMDD HH:mm')

    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "REPORTE-CUENTAS POR COBRAR Y PAGAR POR PERSONA_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const columnsReport = useMemo(() =>[
    { header: 'Nombre Sucursal', accessorKey: 'DocumentoPagoSucursalNombre' },
    { header: 'Tipo Persona', accessorKey: 'DocumentoPagoPersonaTipo' },
    { header: 'Código Persona', accessorKey: 'DocumentoPagoPersonaCodigo' },
    { header: 'Nombre Persona', accessorKey: 'DocumentoPagoPersonaNombre' },
    { header: 'Direccion', accessorKey: 'DocumentoPagoDireccion'},
    // { header: 'Código Documento', accessorKey: 'DocumentoPagoPersonaTipoDocumentoIdentidadCodigo' },
    { header: 'Documento', accessorKey: 'DocumentoPagoPersonaDocumentoIdentidad' },
    // { header: 'Cuenta Nro', accessorKey: 'DocumentoPagoCuentaNumero' },
    { header: 'Nombre Cuenta ', accessorKey: 'DocumentoPagoCuentaDescripcion' },
    { header: 'T. Pago', accessorKey: 'DocumentoPagoContadoCredito' },
    
    { header: 'Fecha Emisión', accessorKey: 'DocumentoPagoFechaEmision' },
    // { header: 'Fecha', accessorKey: 'DocumentoPagoFecha' },
    { header: 'Fecha Vencimiento', accessorKey: 'DocumentoPagoFechaVencimiento' },
    // { header: 'Abreviación Tipo', accessorKey: 'DocumentoPagoTipoAbreviacion' },
    // { header: 'Descripción Tipo', accessorKey: 'DocumentoPagoTipoDescripcion' },
    { header: 'Num Comprobante', accessorKey: 'DocumentoPagoNumero' },
    // { header: 'Número Contable', accessorKey: 'DocumentoPagoNumeroContable' },
    { header: 'Monto Soles', accessorKey: 'DocumentoPagoMontoSoles' },
    { header: 'Saldo Soles', accessorKey: 'DocumentoSaldoMontoSoles' },
    // { header: 'Monto Dólares', accessorKey: 'DocumentoPagoMontoDolares' },
    { header: 'Fecha Último Pago', accessorKey: 'DocumentoPagoFechaUltimoPago' },
    
    { header: 'Ultimo Pago', accessorKey: 'DocumentoMontoUltimoPago' },
    // { header: 'Tipo Cambio', accessorKey: 'DocumentoPagoTipoCambio' },
    // { header: 'Fecha Tipo Cambio', accessorKey: 'DocumentoPagoFechaTipoCambio' },
    
    { header: 'Fecha Programación', accessorKey: 'DocumentoPagoFechaProgramacion' }, 
    { header: 'Motivo no liquidado', accessorKey: 'DocumentoPagoMotivoNoLiquidado' },
    
    // { header: 'Saldo Dólares', accessorKey: 'DocumentoSaldoMontoDolares' },
    { header: 'Código Zona', accessorKey: 'DocumentoPagoZonaCodigo' },
    { header: 'Descripción Zona', accessorKey: 'DocumentoPagoZonaDescripcion' },
    { header: 'Código Responsable', accessorKey: 'DocumentoPagoResponsableCodigo' },
    { header: 'Nombre Responsable', accessorKey: 'DocumentoPagoResponsableNombre' },
    // { header: 'Pagado', accessorKey: 'DocumentoPagoPagado' },  
    // { header: 'Movimiento', accessorKey: 'DocumentoPagoMovimiento' },
    // { header: 'ID Sucursal', accessorKey: 'DocumentoPagoSucursalID' },
    
    // { header: 'Monto Percepción Soles', accessorKey: 'DocumentoMontoPercepcionSoles' },
  ], []);

  const [selectPersona, setselectPersona] = useState({
    "CODIGO":"",
    "NOMBRE":""
  });
  return (
    <>
      <div className="row mt-2">
        <div className="col-lg-12">
          <div className="row mb-1">
          <div className="col-lg-1">    
            <label className="form-label mb-0 " >Fecha:</label>
          </div>
          <div className="col-lg-2" style={{ zIndex: '3' }}>
            <DatePicker 
              locale="es"
              wrapperClassName='input_date'
              className="form-control form-control-sm text-center"
              selected={text_fechaD} 
              onChange={date => setText_fechaD(date)} 
              dateFormat="yyyy-MM-dd"
            />
          </div>
          <div className="col-lg-1">    
            <label className="form-label mb-0" >ALMACEN:</label>
          </div>
          <div className="col-lg-2">
          <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
          </div>
          <div className="col-lg-1">    
            <label className="form-label mb-0" >Contado-Credito:</label>
          </div>
          <div className="col-lg-2">
            <select  className="form-select form-select-sm" value={contadoCredito} onChange={onChangecontadoCredito}>
              <option value={""}  >Seleccione</option>
              <option value="Credito" >Credito</option>
              <option value="Contado" >Contado</option>
          </select>
          </div>
          {/* <div className="col-lg-1">    
            <label className="form-label mb-0" >Cobrar-Pagar:</label>
          </div>
            <div className="col-lg-2">
              <select  className="form-select form-select-sm" value={cobrarPagar} onChange={onChangecobrarPagar} >
                <option value={""}  >Seleccione</option>
                <option value="Cobrar" >Cobrar</option>
                <option value="Pagar" >Pagar</option>
            </select>
            </div> */}
          </div>
          <div className="row">
          
            <div className="col-md-1">Persona:</div>
            <div className="col-md-4">
            <div className='input-group'>
            <SelectPersona setSelectFila={setselectPersona} fila={selectPersona} />
              {/* <input type="text" id="txtDBon2" name="txtDBon" value={persona?.codigo+" - "+persona?.nombre} className="form-control form-control-sm" autoComplete='off' required={true} /> */}
              {/* <AButton tp_tilde="Agregar Persona" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info " bt_vmodal={1} bt_funct={(e)=> { opnenModalPersona()}}  ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} /> */}
              {/* <button className='btn btn-sm btn-icon btn-label-info' onClick={ opnenModalPersona }> 
                 <i className="bx bx-ms bx-plus-medical"></i> 
              </button> */}
              {/*               <AButton tp_tilde="Mas detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info " bt_vmodal={1} bt_nmodal="#exLargeModal2" bt_funct={(e) => { onClickAbrirModal('',arFileUltim.txtDet0) }} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                */}
             </div>
            </div>
            {/* <div className="col-md-1">Cuenta:</div>
            <div className="col-md-4">
            <div className='input-group'>
              <input type="text" readOnly  name="txtDBon" value={dataCuentas?.codigo +"  "+ dataCuentas?.nombre} onChange={onChangecontadoCredito} className="form-control form-control-sm" autoComplete='off' required={true} />
              <button className='btn btn-sm btn-icon btn-label-info' onClick={ handleOpen }> 
                 <i className="bx bx-ms bx-plus-medical"></i> 
              </button>
            </div>
            </div> */}
          </div>
          <hr />
          <div className="row ">
            <div className="col-lg-12 text-end">

              <Tooltip title="Consultar información" placement="top">

                <button title="Consultar información" className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>

              </Tooltip>
            
              <Tooltip title="Exportar" placement="top">
                <div className="btn-group" role="group">
                  <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                    <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                  <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                  <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
              </ul>
                </div>
              </Tooltip>
              <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
            </div>
          </div>
        </div>

      </div>

      <div className="card-datatable table-responsive ">
        <MaterialReactTable
          columns={columnsReport}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{
            density: 'compact', showColumnFilters: false,
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 120, //make actions column wider
            },
          }}
          muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}


        />
      </div>
      {/************  Modal Select ******************/}

      {/* MODAL CUENTA */}
      <Modal
        open={openModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '50%', height: '70%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              // background: 'primary',
              // border: 'none',
              // cursor: 'pointer',
            }}
            className='btn btn-outline-danger btn-sm'
            onClick={cerrarModalPer}
          >
            <i className='fa fa-x'></i>
          </button>
          
          <TablaCuenta selecionaFila={selecionaFila}/>
        </Box>
      </Modal>

      {/* MODAL PERSONA */}
      <Modal
        open={openModalPersona}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '60%', height: '80%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              // background: 'primary',
              // border: 'none',
              // cursor: 'pointer',
            }}
            className='btn btn-outline-danger btn-sm'
            onClick={cerrarModalPersona}
          >
            <i className='fa fa-x'></i>
          </button>
          
          < TablaPersona selectFila={selectFilaPersona}  />

        </Box>
      </Modal>
    </>
  )
}

export const Report2 = (prosp) => { //  FIN002 :  CUENTAS POR COBRAR Y PAGAR POR F.VENCIMIENTO 
  const { dataTipo } = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');
  // variables 
  const [text_fechaD,    setText_fechaD] = useState(new Date());

  const [emp_sl_t_suc,        emp_setSl_t_suc] = useState('');
  const emp_changeSl_t_suc = (event) => { 
    emp_setSl_t_suc(event.target.value);
  }
 
  const [dataReport, setdataReport] = useState([]);
  const [pending, setPending] = useState(false);
  const [openModal, setopenModal] = useState(false);
  const [openModalPersona, setopenModalPersona] = useState(false);

  

  // MODAL PERSONA
  const cerrarModalPersona = () => {
    setopenModalPersona(false);
  }
  const opnenModalPersona = () => {
    setopenModalPersona(true);
  }
  const[ persona, setpersona]=useState({
    codigo:"",
    nombre:""
  });
  

  const selectFilaPersona = (element)=>{
    // console.log(" elec finala ",cod);
    setpersona({
      nombre:element.NOMBRE,
      codigo:element.CODIGO
    })
    cerrarModalPersona()
  }


  //** MODAL CUENTAS  */
  const [dataCuentas, setdataCuentas] = useState({
    nombre:"",
    codigo:""
  });

  const handleOpenCuentas = () => {
    setopenModal(true);
    // funListarcuentas();

  };
  const cerrarModalPer = () => {
    setopenModal(false);
  }
 
  const selecionaFila = (e,rowData) => {
    console.log("dde");
    const data = rowData.original;
    // console.log(data);
    setdataCuentas({
     nombre:data.Nombre,
     codigo:data.Codigo
    });
    setopenModal(false)

  }
  


  //*** SELECT  */

  const [cobrarPagar, setcobrarPagar] = useState('');
  const [contadoCredito, setcontadoCredito] = useState('');

  const  onChangecobrarPagar= (e)=>{
    setcobrarPagar(e.target.value);
  }
  const  onChangecontadoCredito= (e)=>{
    setcontadoCredito(e.target.value);
  }



  const onClickListar = async () => {

    setdataReport([])
    setPending(true)
    const url_dataReport = `/apiRepFinanzasCreditos?`;
    const body = {
                  vTipoReport: dataTipo,
                  vcodEmpresa:   cook_idEmp,
                  sucursal:      emp_sl_t_suc=='9999'?'':emp_sl_t_suc,
                  contadoCredito:contadoCredito,
                  cuenta:        dataCuentas.codigo,
                  cobrarPagar:   cobrarPagar,
                  fecha:         moment(text_fechaD).format('YYYYMMDD'),
                  persona:       persona.codigo
      };

      console.log("body: ", body);

    const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    const response = await fetch(url_dataReport, opPe);
    const data = await response.json();
    // console.log("data :", data);
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    setcobrarPagar('')
    setcontadoCredito('')
    setText_fechaD(new Date())
    setdataCuentas({
      codigo:"",nombre:""
    })
    setpersona({
      codigo:"",nombre:""
    })
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "REPORTE-CUENTAS POR COBRAR Y PAGAR POR F.VENCIMIENTO_"+moment(new Date()).format('YYYYMMDD HH:mm')

    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "REPORTE-CUENTAS POR COBRAR Y PAGAR POR F.VENCIMIENTO_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const columnsReport = useMemo(() =>[
    { header: 'Tipo Persona', accessorKey: 'DocumentoPagoPersonaTipo' },
    { header: 'Código Persona', accessorKey: 'DocumentoPagoPersonaCodigo' },
    { header: 'Nombre Persona', accessorKey: 'DocumentoPagoPersonaNombre' },
    { header: 'Código Documento', accessorKey: 'DocumentoPagoPersonaTipoDocumentoIdentidadCodigo' },
    { header: 'Documento', accessorKey: 'DocumentoPagoPersonaDocumentoIdentidad' },
    { header: 'Cuenta Nro', accessorKey: 'DocumentoPagoCuentaNumero' },
    { header: 'Cuenta Descripción', accessorKey: 'DocumentoPagoCuentaDescripcion' },
    { header: 'Fecha Emisión', accessorKey: 'DocumentoPagoFechaEmision' },
    { header: 'Fecha', accessorKey: 'DocumentoPagoFecha' },
    { header: 'Abreviación Tipo', accessorKey: 'DocumentoPagoTipoAbreviacion' },
    { header: 'Descripción Tipo', accessorKey: 'DocumentoPagoTipoDescripcion' },
    { header: 'Número', accessorKey: 'DocumentoPagoNumero' },
    { header: 'Número Contable', accessorKey: 'DocumentoPagoNumeroContable' },
    { header: 'Monto Soles', accessorKey: 'DocumentoPagoMontoSoles' },
    { header: 'Monto Dólares', accessorKey: 'DocumentoPagoMontoDolares' },
    { header: 'Tipo Cambio', accessorKey: 'DocumentoPagoTipoCambio' },
    { header: 'Fecha Tipo Cambio', accessorKey: 'DocumentoPagoFechaTipoCambio' },
    { header: 'Fecha Vencimiento', accessorKey: 'DocumentoPagoFechaVencimiento' },
    { header: 'Fecha Programación', accessorKey: 'DocumentoPagoFechaProgramacion' },
    { header: 'Fecha Último Pago', accessorKey: 'DocumentoPagoFechaUltimoPago' },
    { header: 'Saldo Soles', accessorKey: 'DocumentoSaldoMontoSoles' },
    { header: 'Saldo Dólares', accessorKey: 'DocumentoSaldoMontoDolares' },
    { header: 'Código Zona', accessorKey: 'DocumentoPagoZonaCodigo' },
    { header: 'Descripción Zona', accessorKey: 'DocumentoPagoZonaDescripcion' },
    { header: 'Código Responsable', accessorKey: 'DocumentoPagoResponsableCodigo' },
    { header: 'Nombre Responsable', accessorKey: 'DocumentoPagoResponsableNombre' },
    { header: 'Pagado', accessorKey: 'DocumentoPagoPagado' },
    { header: 'Cobrar/Pagar', accessorKey: 'DocumentoPagoPorCobrarOPagar' },
    { header: 'Movimiento', accessorKey: 'DocumentoPagoMovimiento' },
    { header: 'ID Sucursal', accessorKey: 'DocumentoPagoSucursalID' },
    { header: 'Nombre Sucursal', accessorKey: 'DocumentoPagoSucursalNombre' },
    { header: 'Monto Percepción Soles', accessorKey: 'DocumentoMontoPercepcionSoles' },
  ], []);


  return (
    <>
      <div className="row mt-2">
        <div className="col-lg-12">
          <div className="row mb-1">
          <div className="col-lg-1">    
            <label className="form-label mb-0 " >Fecha:</label>
          </div>
          <div className="col-lg-2" style={{ zIndex: '3' }}>
            <DatePicker 
              locale="es"
              wrapperClassName='input_date'
              className="form-control form-control-sm text-center"
              selected={text_fechaD} 
              onChange={date => setText_fechaD(date)} 
              dateFormat="yyyy-MM-dd"
            />
          </div>
          <div className="col-lg-1">    
            <label className="form-label mb-0" >ALMACEN:</label>
          </div>
          <div className="col-lg-2">
          <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
          </div>
          <div className="col-lg-1">    
            <label className="form-label mb-0" >Contado-Credito:</label>
          </div>
          <div className="col-lg-2">
            <select  className="form-select form-select-sm" value={contadoCredito} onChange={onChangecontadoCredito}>
              <option value={""}  >Seleccione</option>
              <option value="Credito" >Credito</option>
              <option value="Contado" >Contado</option>
          </select>
          </div>
          <div className="col-lg-1">    
            <label className="form-label mb-0" >Cobrar-Pagar:</label>
          </div>
            <div className="col-lg-2">
              <select  className="form-select form-select-sm" value={cobrarPagar} onChange={onChangecobrarPagar} >
                <option value={""}  >Seleccione</option>
                <option value="Cobrar" >Cobrar</option>
                <option value="Pagar" >Pagar</option>
            </select>
            </div>
          </div>
          <div className="row">
          
            <div className="col-md-1">Persona:</div>
            <div className="col-md-4">
            <div className='input-group'>
              <input type="text" id="txtDBon2" name="txtDBon" value={persona?.codigo+" - "+persona?.nombre} className="form-control form-control-sm" autoComplete='off' required={true} />
              {/* <AButton tp_tilde="Agregar Persona" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info " bt_vmodal={1} bt_funct={(e)=> { opnenModalPersona()}}  ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} /> */}
              <button className='btn btn-sm btn-icon btn-label-info' onClick={ opnenModalPersona }> 
                 <i className="bx bx-ms bx-plus-medical"></i> 
              </button>
              {/*               <AButton tp_tilde="Mas detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info " bt_vmodal={1} bt_nmodal="#exLargeModal2" bt_funct={(e) => { onClickAbrirModal('',arFileUltim.txtDet0) }} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
                */}
             </div>
            </div>
            <div className="col-md-1">Cuenta:</div>
            <div className="col-md-4">
            <div className='input-group'>
              <input type="text" readOnly  name="txtDBon" value={dataCuentas?.codigo +"  "+ dataCuentas?.nombre} onChange={onChangecontadoCredito} className="form-control form-control-sm" autoComplete='off' required={true} />
              <button className='btn btn-sm btn-icon btn-label-info' onClick={ handleOpenCuentas }> 
                 <i className="bx bx-ms bx-plus-medical"></i> 
              </button>
            </div>
            </div>
          </div>
          <hr />
          <div className="row ">
            <div className="col-lg-12 text-end">

              <Tooltip title="Consultar información" placement="top">

                <button title="Consultar información" className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>

              </Tooltip>
            
              <Tooltip title="Exportar" placement="top">
                <div className="btn-group" role="group">
                  <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                    <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                  <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                  <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
              </ul>
                </div>
              </Tooltip>
              <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
            </div>
          </div>
        </div>

      </div>

      <div className="card-datatable table-responsive ">
        <MaterialReactTable
          columns={columnsReport}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{
            density: 'compact', showColumnFilters: false,
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 120, //make actions column wider
            },
          }}
          muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}


        />
      </div>
      {/************  Modal Select ******************/}

      {/* MODAL CUENTA */}
      <Modal
        open={openModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '50%', height: '70%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              // background: 'primary',
              // border: 'none',
              // cursor: 'pointer',
            }}
            className='btn btn-outline-danger btn-sm'
            onClick={cerrarModalPer}
          >
            <i className='fa fa-x'></i>
          </button>
          <TablaCuenta selecionaFila={selecionaFila}/>
        </Box>
      </Modal>

      {/* MODAL PERSONA */}
      <Modal
        open={openModalPersona}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '50%', height: '80%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              // background: 'primary',
              // border: 'none',
              // cursor: 'pointer',
            }}
            className='btn btn-outline-danger btn-sm'
            onClick={cerrarModalPersona}
          >
            <i className='fa fa-x'></i>
          </button>
            < TablaPersona selectFila={selectFilaPersona}  />
        </Box>
      </Modal>
    </>
  )
}
export const Report3 = (prosp) => { // FIN007 :  CUENTA CORRIENTE DE CLIENTE
  const { dataTipo } = prosp
  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');
  // variables 
  const [text_fechaD,    setText_fechaD] = useState(new Date());
  const [text_fechaF,    setText_fechaF] = useState(new Date());

  const [emp_sl_t_suc,        emp_setSl_t_suc] = useState('');
  const emp_changeSl_t_suc = (event) => { 
    emp_setSl_t_suc(event.target.value);
  }
 
  const [dataReport, setdataReport] = useState([]);
  const [pending, setPending] = useState(false);

  const [openModalPersona, setopenModalPersona] = useState(false);

  

  // MODAL PERSONA
  const cerrarModalPersona = () => {
    setopenModalPersona(false);
  }
  const opnenModalPersona = () => {
    setopenModalPersona(true);
  }
  const[ persona, setpersona]=useState({
    codigo:"",
    nombre:""
  });
  

  const selectFilaPersona = (element)=>{
    // console.log(" elec finala ",cod);
    setpersona({
      nombre:element.NOMBRE,
      codigo:element.CODIGO
    })
    cerrarModalPersona()
  }


  //** MODAL CUENTAS  */
  const [dataCuentas, setdataCuentas] = useState({
    nombre:"",
    codigo:""
  });
  const [cuentaFinIni,setcuentaFinIni] = useState('')
  const [openModal, setopenModal] = useState(false);
  const handleOpen = (cuenta) => {
    setopenModal(true);
    setcuentaFinIni(cuenta)
    // funListarcuentas();

  };
  const cerrarModalPer = () => {
    setopenModal(false);
  }
 
  const selecionaFila = (e,rowData) => {
    console.log("rowData", rowData);
    const data = rowData.original;
    // console.log(data);cuentaFinIni
    const elemtCuenta={
      nombre:data.Nombre,
      codigo:data.Codigo
     }
     console.log("cuentaFinIni:", cuentaFinIni);
     if(cuentaFinIni==='cInicio'){
      setdataCuentas(elemtCuenta)
     }
     if(cuentaFinIni==='cFin'){
      setdataCuentas2(elemtCuenta)
     }
    
    setopenModal(false)

  }


  

  const [dataCuentas2, setdataCuentas2] = useState({
    nombre:"",
    codigo:""
  });


  const onClickListar = async () => {

    // setdataReport([])
    setPending(true)
    const url_dataReport = `/apiRepFinanzasCreditos?`;
    const body = {
                  vTipoReport: dataTipo,
                  vcodEmpresa:   cook_idEmp,
                  sucursal:      emp_sl_t_suc=='9999'?'':emp_sl_t_suc,
                  cuenta:        dataCuentas.codigo,
                  cuenta2:        dataCuentas2.codigo,
                  fecha:         moment(text_fechaD).format('YYYYMMDD'),
                  fecha2:         moment(text_fechaF).format('YYYYMMDD'),
                  persona:       persona.codigo,
      };

    console.log("body : ", body);

    const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    const response = await fetch(url_dataReport, opPe);
    const data = await response.json();
    console.log("DAta deReporte  :", data);
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    setText_fechaD(new Date())
    setText_fechaF(new Date())
    setdataCuentas({
      codigo:"",nombre:""
    })
    setpersona({
      codigo:"",nombre:""
    })
  }
  const onClickCSV = async () => {
  }
  const onClickExcel = async () => {
  }
  // const columnsReport = useMemo(() =>
  //   [
  //     { header: 'ID', accessorKey: 'DocumentoPagoID' },
  //     { header: 'Persona Codigo', accessorKey: 'DocumentoPagoPersonaCodigo' },
  //     { header: 'Persona Nombre', accessorKey: 'DocumentoPagoPersonaNombre' },
  //     { header: 'Cuenta Numero', accessorKey: 'DocumentoPagoCuentaNumero' },
  //     { header: 'Fecha Emision', accessorKey: 'DocumentoPagoFechaEmision' },
  //     { header: 'Fecha', accessorKey: 'DocumentoPagoFecha' },
  //     { header: 'Tipo Abreviacion', accessorKey: 'DocumentoPagoTipoAbreviacion' },
  //     { header: 'Numero', accessorKey: 'DocumentoPagoNumero' },
  //     { header: 'Numero Contable', accessorKey: 'DocumentoPagoNumeroContable' },
  //     { header: 'Soles Cargo', accessorKey: 'DocumentoPagoSolesCargo' },
  //     { header: 'Soles Abono', accessorKey: 'DocumentoPagoSolesAbono' },
  //     { header: 'Dolares Cargo', accessorKey: 'DocumentoPagoDolaresCargo' },
  //     { header: 'Dolares Abono', accessorKey: 'DocumentoPagoDolaresAbono' },
  //     { header: 'Fecha', accessorKey: 'AmortizacionFecha' },
  //     { header: 'Tipo Abreviacion', accessorKey: 'AmortizacionTipoAbreviacion' },
  //     { header: 'Numero', accessorKey: 'AmortizacionNumero' },
  //     { header: 'Numero Contable', accessorKey: 'AmortizacionNumeroContable' },
  //     { header: 'Soles Cargo', accessorKey: 'AmortizacionSolesCargo' },
  //     { header: 'Soles Abono', accessorKey: 'AmortizacionSolesAbono' },
  //     { header: 'Dolares Cargo', accessorKey: 'AmortizacionDolaresCargo' },
  //     { header: 'Dolares Abono', accessorKey: 'AmortizacionDolaresAbono' },
  //     { header: 'Orden', accessorKey: 'Orden' }
  //   ], []);
    const columnsReport = [
      { header: 'ID', accessorKey: 'DocumentoPagoID' },
      { header: 'Persona Codigo', accessorKey: 'DocumentoPagoPersonaCodigo' },
      { header: 'Persona Nombre', accessorKey: 'DocumentoPagoPersonaNombre' },
      { header: 'Cuenta Numero', accessorKey: 'DocumentoPagoCuentaNumero' },
      { header: 'Fecha Emision', accessorKey: 'DocumentoPagoFechaEmision' },
      { header: 'Fecha', accessorKey: 'DocumentoPagoFecha' },
      { header: 'Tipo Abreviacion', accessorKey: 'DocumentoPagoTipoAbreviacion' },
      { header: 'Numero', accessorKey: 'DocumentoPagoNumero' },
      { header: 'Numero Contable', accessorKey: 'DocumentoPagoNumeroContable' },
      { header: 'Soles Cargo', accessorKey: 'DocumentoPagoSolesCargo' },
      { header: 'Soles Abono', accessorKey: 'DocumentoPagoSolesAbono' },
      { header: 'Dolares Cargo', accessorKey: 'DocumentoPagoDolaresCargo' },
      { header: 'Dolares Abono', accessorKey: 'DocumentoPagoDolaresAbono' },
      { header: 'Fecha', accessorKey: 'AmortizacionFecha' },
      { header: 'Tipo Abreviacion', accessorKey: 'AmortizacionTipoAbreviacion' },
      { header: 'Numero', accessorKey: 'AmortizacionNumero' },
      { header: 'Numero Contable', accessorKey: 'AmortizacionNumeroContable' },
      { header: 'Soles Cargo', accessorKey: 'AmortizacionSolesCargo' },
      { header: 'Soles Abono', accessorKey: 'AmortizacionSolesAbono' },
      { header: 'Dolares Cargo', accessorKey: 'AmortizacionDolaresCargo' },
      { header: 'Dolares Abono', accessorKey: 'AmortizacionDolaresAbono' },
      { header: 'Orden', accessorKey: 'Orden' }
    ]

    const InputGroup = ({ label, value, handleOpen, openKey }) => (
      <div className="form-group row mb-1">
          <label className="col-md-2 col-sm-3 col-form-label">sdsdsd</label>

        <div className="col-md-4 col-sm-9">
          <div className="input-group">
            <input
              type="text"
              readOnly
              value="dsdsd"
              className="form-control form-control-sm"
              autoComplete="off"
              required
            />
            <button
              className="btn btn-sm btn-icon btn-label-info"
              // onClick={() => handleOpen(openKey)}
            >
              <i className="bx bx-ms bx-plus-medical"></i>
            </button>
          </div>
        </div>
      </div>
    );
    
    const ImprovedComponent = ({ dataCuentas, dataCuentas2, handleOpen }) => (
      <div className="container">
        <InputGroup
          label="Cuenta I"
          value={"sdsdds"}
          // handleOpen={handleOpen}
          openKey="cInicio"
        />
        <InputGroup
          label="Cuenta F"
          value={"dsds"}
          // handleOpen={handleOpen}
          openKey="cFin"
        />
      </div>
    );
    

  return (
    <>
        <div className="row mt-2">
          {/* <div className="col-md-1">Fecha I:  </div> */}
          <div className="col-md-2" style={{ zIndex: '3' }}>
          <label className="form-label mb-0 " >Fecha I:</label>
            <DatePicker
              locale="es"
              wrapperClassName='input_date'
              className="form-control form-control-sm text-center"
              selected={text_fechaD}
              onChange={date => setText_fechaD(date)}
              dateFormat="yyyy-MM-dd"
            />
          </div>
          {/* <div className="col-md-1">Fecha F:  </div> */}
        <div className="col-md-2" style={{ zIndex: '3' }}>
        <label className="form-label mb-0 " >Fecha F:</label>
        <DatePicker
                    locale="es"
                    wrapperClassName='input_date'
                    className="form-control form-control-sm text-center col-lg-1"
                    selected={text_fechaF}
                    onChange={date => setText_fechaF(date)}
                    dateFormat="yyyy-MM-dd"
                  />

        </div>
        {/* <div className="col-md-1">Sucu:</div> */}
        <div className="col-md-2" >
        <label className="form-label mb-0 " >Sucursal:</label>
        <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
        </div> 

        <div className="col-md-6" >
          <label className="form-label mb-0 " >Persona:</label>
          <div className='input-group'>
            <input type="text" id="txtDBon2" name="txtDBon" value={persona?.codigo + " - " + persona?.nombre} className="form-control form-control-sm" autoComplete='off' required={true} />
            {/* <AButton tp_tilde="Agregar Persona" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info " bt_vmodal={1} bt_funct={(e)=> { opnenModalPersona()}}  ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} /> */}
            <button className='btn btn-sm btn-icon btn-label-info' onClick={opnenModalPersona}>
              <i className="bx bx-ms bx-plus-medical"></i>
            </button>
            {/*               <AButton tp_tilde="Mas detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info " bt_vmodal={1} bt_nmodal="#exLargeModal2" bt_funct={(e) => { onClickAbrirModal('',arFileUltim.txtDet0) }} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
              */}
          </div>
        </div>
      </div>
       
      <div className="row">
          
        {/* <div className="col-md-1">Cuent I:</div> */}
        <div className="col-md-5" >
          <label className="form-label mb-0 " >Cuenta I:</label>
          <div className='input-group'>
            <input type="text" readOnly name="txtDBon" value={`${dataCuentas?.codigo || ''} ${dataCuentas?.nombre || ''}`}
              className="form-control form-control-sm"
              autoComplete='off'
              required
            />
            <button className='btn btn-sm btn-icon btn-label-info' onClick={() => handleOpen("cInicio")} ><i className="bx bx-ms bx-plus-medical"></i>  </button>
          </div>
        </div> 


        {/* <div className="col-md-1 col-sm-3 ">Cuent F:</div> */}
        <div className="col-md-5">
          <label className="form-label mb-0 " >Cuenta F:</label>
          <div className='input-group'>
            <input
              type="text"
              readOnly
              name="txtDBon"
              value={`${dataCuentas2?.codigo || ''} ${dataCuentas2?.nombre || ''}`}
              className="form-control form-control-sm"
              autoComplete='off'
              required
            />
            <button
              className='btn btn-sm btn-icon btn-label-info'
              onClick={() => handleOpen("cFin")}
            >
              <i className="bx bx-ms bx-plus-medical"></i>
            </button>
          </div>
        </div>
      </div>
      {/* <div className="row ">
        <div className="col-md-1">Persona</div>
        <div className="col-sm-5">
          <div className='input-group'> */}
            {/* <input type="text" id="txtDBon2" name="txtDBon" value={persona?.codigo + " - " + persona?.nombre} className="form-control form-control-sm" autoComplete='off' required={true} /> */}
            {/* <AButton tp_tilde="Agregar Persona" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info " bt_vmodal={1} bt_funct={(e)=> { opnenModalPersona()}}  ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} /> */}
            {/* <button className='btn btn-sm btn-icon btn-label-info' onClick={opnenModalPersona}>
              <i className="bx bx-ms bx-plus-medical"></i>
            </button> */}
            {/*               <AButton tp_tilde="Mas detalle" tp_posit="top" bt_class="btn btn-sm btn-icon btn-label-info " bt_vmodal={1} bt_nmodal="#exLargeModal2" bt_funct={(e) => { onClickAbrirModal('',arFileUltim.txtDet0) }} ic_class="bx bx-ms bx-plus-medical" vl_tpbtn={1} />
              */}
          {/* </div>
        </div>
      </div> */}
      <div className="row ">
        <div className="col-lg-12 text-end">

          <Tooltip title="Consultar información" placement="top">

            <button title="Consultar información" className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
              <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
              <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
            </button>

          </Tooltip>

          <Tooltip title="Exportar" placement="top">
            <div className="btn-group" role="group">
              <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
              </button>
              <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
              <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
              <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
              </ul>
            </div>
          </Tooltip>
          <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>
      <div className="card-datatable table-responsive ">
        <MaterialReactTable
          columns={columnsReport}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{
            density: 'compact', showColumnFilters: false,
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 120, //make actions column wider
            },
          }}
          muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}


        />
      </div>
      {/************  Modal Select ******************/}

      {/* MODAL CUENTA */}
      <Modal
        open={openModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '50%', height: '70%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              // background: 'primary',
              // border: 'none',
              // cursor: 'pointer',
            }}
            className='btn btn-outline-danger btn-sm'
            onClick={cerrarModalPer}
          >
            <i className='fa fa-x'></i>
          </button>
          
          <TablaCuenta selecionaFila={selecionaFila}/>
        </Box>
      </Modal>

      {/* MODAL PERSONA */}
      <Modal
        open={openModalPersona}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '60%', height: '70%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              // background: 'primary',
              // border: 'none',
              // cursor: 'pointer',
            }}
            className='btn btn-outline-danger btn-sm'
            onClick={cerrarModalPersona}
          >
            <i className='fa fa-x'></i>
          </button>
          
          < TablaPersona selectFila={selectFilaPersona}  />

        </Box>
      </Modal>
    </>
  )
}
export const Report4 = (prosp) => { // FIN004 :  CUENTAS POR COBRAR POR VENDEDORES POR F. VENCIMIENTO
  const { dataTipo } = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');
  // variables 
  const [text_fechaD,    setText_fechaD] = useState(new Date());

  const [emp_sl_t_suc,        emp_setSl_t_suc] = useState('');
  const emp_changeSl_t_suc = (event) => { 
    emp_setSl_t_suc(event.target.value);
  }
 
  const [dataReport, setdataReport] = useState([]);
  const [pending, setPending] = useState(false);
  const [archExcel, setArchExcel] = useState(null);
  const [archCsv, setArchCsv] = useState(null);
  const [selectVendedor, setselectVendedor] = useState({
    "Codigo Vendedor":"",
    "Nombre Vendedor":""
  });

  //** MODAL CUENTAS  */
  const [dataCuentas, setdataCuentas] = useState({
    nombre:"",
    codigo:""
  });

  

  //*** SELECT  */

  const [cobrarPagar, setcobrarPagar] = useState('');
  const [contadoCredito, setcontadoCredito] = useState('');

  const  onChangecobrarPagar= (e)=>{
    setcobrarPagar(e.target.value);
  }
  const  onChangecontadoCredito= (e)=>{
    setcontadoCredito(e.target.value);
  }



  const onClickListar = async () => {
    try {
      setdataReport([])
      setPending(true)
      const url_dataReport = `/apiRepFinanzasCreditos_Archivo?`;
      const body = {
        vTipoReport: dataTipo,
        vcodEmpresa: cook_idEmp,
        sucursal: emp_sl_t_suc == '9999' ? '' : emp_sl_t_suc,
        contadoCredito: contadoCredito,
        cobrarPagar: cobrarPagar,
        fecha: moment(text_fechaD).format('YYYYMMDD'),
        codigoVendedor: selectVendedor['Codigo Vendedor']
      };

      // console.log("body: ", body);

      const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
      const response = await fetch(url_dataReport, opPe);
         // Validando si la respuesta fue exitosa
      if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Error desconocido');
      }
      const res = await response.json();
      if(res.data.length==0) notify_warning("No se encontraron registros");
      
      setArchExcel(res.docExcel);
      setArchCsv(res.docCsv)
      setdataReport(res.data);
      // console.log("data:: ", data.data);
        
      // if(data.data.length <= 0){
      //   notify_warning("No se encontraron registros")
        
      // }else{
      //   setdataArchivo(data.archivo);
      //   setdataReport(data.data);
      // }
      
      setPending(false)
    } catch (error) {
      notify_warning("No se encontraron registros")
    }finally{
      setPending(false)
    }
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    setcobrarPagar('')
    setcontadoCredito('')
    setText_fechaD(new Date())
    setselectVendedor({
      "Codigo Vendedor":"",
      "Nombre Vendedor":""
    })
   
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "REPORTE-CUENTAS POR COBRAR POR VENDEDORES POR F. VENCIMIENTO_"+moment(new Date()).format('YYYYMMDD HH:mm')
    
    const showData = async () => {
      try {
          // Decodifica el archivo base64
          const csvData = atob(archCsv);
          // Crea un Blob con el CSV
          const blob = new Blob([csvData], { type: 'text/csv' });
          const data = new Blob([blob], {type:fileType});
          FileSaver.saveAs(data, `${nameFileType}.CSV`);

      } catch (error) {
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "REPORTE-CUENTAS POR COBRAR POR VENDEDORES POR F. VENCIMIENTO_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {

      try {
        // Supongo que `dataArchivo` contiene los datos del archivo en formato Excel en base64
        const excelData = atob(archExcel); // Decodifica el Base64 a una cadena binaria

        // Convierte la cadena binaria a un array de bytes
        const uint8Array = new Uint8Array([...excelData].map(c => c.charCodeAt(0)));

        // Crea un Blob con el array de bytes
        const blob = new Blob([uint8Array], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Usar FileSaver.js para descargar el archivo
        FileSaver.saveAs(blob, `${nameFileType}.xlsx`);

      } catch (error) {
        notify_warning("No existen datos, verificar campos")

      }

  


    //   // Supongo que `dataArchivo` contiene los datos del archivo en formato Excel en base64
    // const excelData = atob(dataArchivo);

    // // Crear un Blob con el archivo Excel
    //  const blob = new Blob([new Uint8Array([...excelData].map(c => c.charCodeAt(0)))], {
    //     type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    //   });
    // // const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // // Usar FileSaver.js para descargar el archivo
    // saveAs(blob, `${nameFileType}.xlsx`);


    


      // const excelBlob = atob(dataArchivo);
      // const blob = new Blob([new Uint8Array([...excelBlob].map(c => c.charCodeAt(0)))], {
      //   type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      // });
      // // Decodifica el archivo base64
      // const csvData = atob(dataArchivo);
      // // Crea un Blob con el CSV
      // // const blob = new Blob([csvData], { type: 'text/csv' });


      // /** */
      // const data = new Blob([blob], {type:fileType});
      // FileSaver.saveAs(data, `${nameFileType}.xlsx`);

      /**
       * 
       */
      // const url = window.URL.createObjectURL(blob);
      // const a = document.createElement('a');
      // a.href = url;
      // a.download = nameFileType+'.csv';
      // a.click();
      // window.URL.revokeObjectURL(url);
      // if (dataReport.length !== 0 ) {
      //   const ws = XLSX.utils.json_to_sheet(dataReport);
      //   // Cabecera del archivo 
      //   // const header = []; // Cambia esto según tus necesidades
      //   // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
      //   const wb = {Sheets:{data:ws}, SheetNames:["data"]};
      //   const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
      //   const data = new Blob([excelBuffer], {type:fileType});
      //   FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      // }else{
      //   notify_warning("No existen datos, verificar campos")
      // }


    }
    showData();
  }
  const columnsReport = useMemo(() =>[

   
    { header: 'Código Vendedor', accessorKey: 'DocumentoPagoResponsableCodigo' },
    { header: 'Nombre Vendedor', accessorKey: 'DocumentoPagoResponsableNombre' },
    { header: 'Zona Código', accessorKey: 'DocumentoPagoZonaCodigo' },
    { header: 'Zona Descripcion.', accessorKey: 'DocumentoPagoZonaDescripcion' },
    { header: 'Direccion', accessorKey: 'DocumentoPagoDireccion'},
    
    { header: 'Tipo', accessorKey: 'DocumentoPagoPersonaTipo' },
    { header: 'Código', accessorKey: 'DocumentoPagoPersonaCodigo' },
    { header: 'Nombre', accessorKey: 'DocumentoPagoPersonaNombre' },
    { header: 'Tipo de Documento', accessorKey: 'DocumentoPagoPersonaTipoDocumentoIdentidadCodigo' },
    { header: 'Documento de Identidad', accessorKey: 'DocumentoPagoPersonaDocumentoIdentidad' },
    // { header: 'Cuenta Nro', accessorKey: 'DocumentoPagoCuentaNumero' },
    { header: 'Cuenta Descrip.', accessorKey: 'DocumentoPagoCuentaDescripcion' },
    { header: 'Fecha de Emisión', accessorKey: 'DocumentoPagoFechaEmision' },
    { header: 'Fecha de Programación', accessorKey: 'DocumentoPagoFechaProgramacion' },

    // { header: 'Abrev.', accessorKey: 'DocumentoPagoTipoAbreviacion' },
    // { header: 'Descripción', accessorKey: 'DocumentoPagoTipoDescripcion' },
    { header: 'Número de Documento', accessorKey: 'DocumentoPagoNumero' },
    // { header: 'Número Contable', accessorKey: 'DocumentoPagoNumeroContable' },
    { header: 'Monto S/', accessorKey: 'DocumentoPagoMontoSoles' },
    // { header: 'Monto $', accessorKey: 'DocumentoPagoMontoDolares' },
    // { header: 'Tipo Cambio', accessorKey: 'DocumentoPagoTipoCambio' },
    // { header: 'Fecha TC', accessorKey: 'DocumentoPagoFechaTipoCambio' },
    { header: 'Vencimiento', accessorKey: 'DocumentoPagoFechaVencimiento' },
    { header: 'Fecha Último Pago', accessorKey: 'DocumentoPagoFechaUltimoPago' },
    { header: 'Ultimo Pago', accessorKey: 'DocumentoMontoUltimoPago' },
    { header: 'Saldo S/', accessorKey: 'DocumentoSaldoMontoSoles' },
    { header: 'Motivo no liquidado', accessorKey: 'DocumentoPagoMotivoNoLiquidado' },
    // { header: 'Saldo $', accessorKey: 'DocumentoSaldoMontoDolares' },
    // { header: 'Zona Código', accessorKey: 'DocumentoPagoZonaCodigo' },
    // { header: 'Zona Desc.', accessorKey: 'DocumentoPagoZonaDescripcion' },
    // { header: 'Direccion', accessorKey: 'DocumentoPagoDireccion'},
    // { header: 'Responsable Código', accessorKey: 'DocumentoPagoResponsableCodigo' },
    // { header: 'Responsable', accessorKey: 'DocumentoPagoResponsableNombre' },
    // { header: 'Pagado', accessorKey: 'DocumentoPagoPagado' },
    { header: 'Modalidad', accessorKey: 'DocumentoPagoModalidadCodigo' },
    // { header: 'Movimiento', accessorKey: 'DocumentoPagoMovimiento' },
    { header: 'Sucursal ID', accessorKey: 'DocumentoPagoSucursalID' },
    { header: 'Sucursal', accessorKey: 'DocumentoPagoSucursalNombre' },
    
], []);


  return (
    <>
      <div className="row mt-3">
        {/* <div className="col-md-1">
          <label className="form-label mb-0 " >Fecha:</label>
        </div> */}
        <div className="col-md-3" style={{ zIndex: '4' }}>
        <label className="form-label mb-0 " >Fecha:</label>
          <DatePicker
            locale="es"
            wrapperClassName='input_date'
            className="form-control form-control-sm text-center"
            selected={text_fechaD}
            onChange={date => setText_fechaD(date)}
            dateFormat="yyyy-MM-dd"
          />
        </div>
        {/* <div className="col-md-1">
          <label className="form-label mb-0" >ALMACEN:</label>
        </div> */}
        <div className="col-md-3">
        <label className="form-label mb-0" >ALMACEN:</label>
          <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
        </div>
        {/* <div className="col-lg-2">
          <label className="form-label mb-0" >Contado-Credito:</label>
        </div> */}
        <div className="col-lg-4">
          <label className="form-label mb-0" >Contado-Credito:</label>
          <select className="form-select form-select-sm" value={contadoCredito} onChange={onChangecontadoCredito}>
            <option value={""}  >Seleccione</option>
            <option value="Credito" >Credito</option>
            <option value="Contado" >Contado</option>
          </select>
        </div>
        
      </div>
      <div className="row mt-1">
        <div className="col-md-1">Persona:</div>
        <div className="col-md-5">
        <SelectVendedor setSelectFila={setselectVendedor} fila={selectVendedor} />
        </div>
        {/* <div className="col-lg-2">
          <label className="form-label mb-0" >Cobrar-Pagar:</label>
        </div>
        <div className="col-lg-2">
          <select className="form-select form-select-sm" value={cobrarPagar} onChange={onChangecobrarPagar} >
            <option value={""}  >Seleccione</option>
            <option value="Cobrar" >Cobrar</option>
            <option value="Pagar" >Pagar</option>
          </select>
        </div> */}
      </div>
      <hr />
      <div className="row ">
        <div className="col-lg-12 text-end">
          <Tooltip title="Consultar información" placement="top">
            <button title="Consultar información" className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
              <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
              <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
            </button>
          </Tooltip>
          {
            (dataReport.length > 0) && <>
              <Tooltip title="Exportar" placement="top">
                <div className="btn-group" role="group">
                  <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                    <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                    <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                    <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                  </ul>
                </div>
              </Tooltip>
            </>
          }
         
          <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>
      <div className="card-datatable table-responsive ">
        <MaterialReactTable
          columns={columnsReport}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{
            density: 'compact', showColumnFilters: false,
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 120, //make actions column wider
            },
          }}
          muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}


        />
      </div>
      
    </>
  )
}

export const Report5 = (prosp) => { // FIN005 :  CUENTA CORRIENTE DE EMPLEADO
  const { dataTipo } = prosp
  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');
  // variables 
  const [text_fechaD,    setText_fechaD] = useState(new Date());
  const [text_fechaF,    setText_fechaF] = useState(new Date());

  const [emp_sl_t_suc,        emp_setSl_t_suc] = useState('');
  const emp_changeSl_t_suc = (event) => { 
    emp_setSl_t_suc(event.target.value);
  }
 
  const [dataReport, setdataReport] = useState([]);
  const [pending, setPending] = useState(false);

  const [openModalPersona, setopenModalPersona] = useState(false);


  // MODAL PERSONA
  const cerrarModalPersona = () => {
    setopenModalPersona(false);
  }
  
  const[ persona, setpersona]=useState({
    codigo:"",
    nombre:""
  });
  
  const [selectVendedor, setselectVendedor] = useState({
    "Codigo Vendedor":"",
    "Nombre Vendedor":""
  });

  const selectFilaPersona = (element)=>{
    // console.log(" elec finala ",cod);
    setpersona({
      nombre:element.NOMBRE,
      codigo:element.CODIGO
    })
    cerrarModalPersona()
  }


  //** MODAL CUENTAS  */
  const [dataCuentas, setdataCuentas] = useState({
    nombre:"",
    codigo:""
  });
  const [cuentaFinIni,setcuentaFinIni] = useState('')
  const [openModal, setopenModal] = useState(false);
  const handleOpen = (cuenta) => {
    setopenModal(true);
    setcuentaFinIni(cuenta)
    // funListarcuentas();

  };
  const cerrarModalPer = () => {
    setopenModal(false);
  }
 
  const selecionaFila = (e,rowData) => {
    console.log("rowData", rowData);
    const data = rowData.original;
    // console.log(data);cuentaFinIni
    const elemtCuenta={
      nombre:data.Nombre,
      codigo:data.Codigo
     }
     console.log("cuentaFinIni:", cuentaFinIni);
     if(cuentaFinIni==='cInicio'){
      setdataCuentas(elemtCuenta)
     }
     if(cuentaFinIni==='cFin'){
      setdataCuentas2(elemtCuenta)
     }
    
    setopenModal(false)

  }


  const [dataCuentas2, setdataCuentas2] = useState({
    nombre:"",
    codigo:""
  });


  const onClickListar = async () => {

    // setdataReport([])
    setPending(true)
    const url_dataReport = `/apiRepFinanzasCreditos?`;
    const body = {
                  vTipoReport: dataTipo,
                  vcodEmpresa:   cook_idEmp,
                  sucursal:      emp_sl_t_suc=='9999'?'':emp_sl_t_suc,
                  cuenta:        dataCuentas.codigo,
                  cuenta2:        dataCuentas2.codigo,
                  fecha:         moment(text_fechaD).format('YYYYMMDD'),
                  fecha2:         moment(text_fechaF).format('YYYYMMDD'),
                  codigoVendedor: selectVendedor['Codigo Vendedor'],
      };

    console.log("body : ", body);

    const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    const response = await fetch(url_dataReport, opPe);
    const data = await response.json();
    console.log("DAta deReporte  :", data);
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    setText_fechaD(new Date())
    setText_fechaF(new Date())
    setdataCuentas({
      codigo:"", nombre :""
    })
    setdataCuentas2({
      codigo:"",nombre:""

    })
    setselectVendedor({
      "Codigo Vendedor":"",
      "Nombre Vendedor":""
    })
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "REPORTE-CUENTA CORRIENTE DE EMPLEADO_"+moment(new Date()).format('YYYYMMDD HH:mm')

    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "REPORTE-CUENTA CORRIENTE DE EMPLEADO_"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  
  const columnsReport = useMemo(() =>
    [
      { header: 'ID', accessorKey: 'DocumentoPagoID' },
      { header: 'Código', accessorKey: 'DocumentoPagoPersonaCodigo' },
      { header: 'Nombre', accessorKey: 'DocumentoPagoPersonaNombre' },
      { header: 'Cuenta Nro', accessorKey: 'DocumentoPagoCuentaNumero' },
      { header: 'Emisión', accessorKey: 'DocumentoPagoFechaEmision' },
      { header: 'Fecha', accessorKey: 'DocumentoPagoFecha' },
      { header: 'Abrev. Tipo', accessorKey: 'DocumentoPagoTipoAbreviacion' },
      { header: 'Número', accessorKey: 'DocumentoPagoNumero' },
      { header: 'Número Contable', accessorKey: 'DocumentoPagoNumeroContable' },
      { header: 'Soles Cargo', accessorKey: 'DocumentoPagoSolesCargo' },
      { header: 'Soles Abono', accessorKey: 'DocumentoPagoSolesAbono' },
      { header: 'Dolares Cargo', accessorKey: 'DocumentoPagoDolaresCargo' },
      { header: 'Dolares Abono', accessorKey: 'DocumentoPagoDolaresAbono' },
      { header: 'Amort. Fecha', accessorKey: 'AmortizacionFecha' },
      { header: 'Amort. Abrev.', accessorKey: 'AmortizacionTipoAbreviacion' },
      { header: 'Amort. Nro', accessorKey: 'AmortizacionNumero' },
      { header: 'Amort. Contable', accessorKey: 'AmortizacionNumeroContable' },
      { header: 'Amort. Soles Cargo', accessorKey: 'AmortizacionSolesCargo' },
      { header: 'Amort. Soles Abono', accessorKey: 'AmortizacionSolesAbono' },
      { header: 'Amort. Dolares Cargo', accessorKey: 'AmortizacionDolaresCargo' },
      { header: 'Amort. Dolares Abono', accessorKey: 'AmortizacionDolaresAbono' },
      { header: 'Orden', accessorKey: 'Orden' }
  ]
  
, []);

    const InputGroup = ({ label, value, handleOpen, openKey }) => (
      <div className="form-group row mb-1">
          <label className="col-md-2 col-sm-3 col-form-label">sdsdsd</label>

        <div className="col-md-4 col-sm-9">
          <div className="input-group">
            <input
              type="text"
              readOnly
              value="dsdsd"
              className="form-control form-control-sm"
              autoComplete="off"
              required
            />
            <button
              className="btn btn-sm btn-icon btn-label-info"
              // onClick={() => handleOpen(openKey)}
            >
              <i className="bx bx-ms bx-plus-medical"></i>
            </button>
          </div>
        </div>
      </div>
    );
    
    const ImprovedComponent = ({ dataCuentas, dataCuentas2, handleOpen }) => (
      <div className="container">
        <InputGroup
          label="Cuenta I"
          value={"sdsdds"}
          // handleOpen={handleOpen}
          openKey="cInicio"
        />
        <InputGroup
          label="Cuenta F"
          value={"dsds"}
          // handleOpen={handleOpen}
          openKey="cFin"
        />
      </div>
    );
    

  return (
    <>
      <div className="row mt-2">
        <div className="col-md-1">Fecha I:  </div>
        <div className="col-md-2" style={{ zIndex: '3' }}>
          <DatePicker
            locale="es"
            wrapperClassName='input_date'
            className="form-control form-control-sm text-center"
            selected={text_fechaD}
            onChange={date => setText_fechaD(date)}
            dateFormat="yyyy-MM-dd"
          />
        </div>
        <div className="col-md-1">Fecha F:  </div>
        <div className="col-md-2" style={{ zIndex: '3' }}>
          <DatePicker
            locale="es"
            wrapperClassName='input_date'
            className="form-control form-control-sm text-center col-lg-1"
            selected={text_fechaF}
            onChange={date => setText_fechaF(date)}
            dateFormat="yyyy-MM-dd"
          />

        </div>
        <div className="col-md-1"></div>
        <div className="col-md-1">Sucursal:</div>
        <div className="col-md-4" >
          <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
        </div>

      </div>



      <div className="row mt-1">

        <div className="col-md-1">Cuent Ini:</div>
        <div className="col-md-5" >
          <div className='input-group'>
            <input type="text" readOnly name="txtDBon" value={`${dataCuentas?.codigo || ''} ${dataCuentas?.nombre || ''}`}
              className="form-control form-control-sm"
              autoComplete='off'
              required
            />
            <button className='btn btn-sm btn-icon btn-label-info' onClick={() => handleOpen("cInicio")} ><i className="bx bx-ms bx-plus-medical"></i>  </button>
          </div>
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-1">Vendedor </div>
        <div className="col-sm-4">
          <div className='input-group'>
            <SelectVendedor setSelectFila={setselectVendedor} fila={selectVendedor} />
          </div>
        </div>

      </div>
      <div className="row ">
        
      <div className="col-md-1 col-sm-3 ">Cuent Fin:</div>
        <div className="col-md-5">
          <div className='input-group'>
            <input
              type="text"
              readOnly
              name="txtDBon"
              value={`${dataCuentas2?.codigo || ''} ${dataCuentas2?.nombre || ''}`}
              className="form-control form-control-sm"
              autoComplete='off'
              required
            />
            <button
              className='btn btn-sm btn-icon btn-label-info'
              onClick={() => handleOpen("cFin")}
            >
              <i className="bx bx-ms bx-plus-medical"></i>
            </button>
          </div>
        </div>
       
       
      </div>
      <div className="row ">
        <div className="col-lg-12 text-end">

          <Tooltip title="Consultar información" placement="top">

            <button title="Consultar información" className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
              <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
              <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
            </button>

          </Tooltip>

          <Tooltip title="Exportar" placement="top">
            <div className="btn-group" role="group">
              <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
              </button>
              <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
              <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
              <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
              </ul>
            </div>
          </Tooltip>
          <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>
      <div className="card-datatable table-responsive ">
        <MaterialReactTable
          columns={columnsReport}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{
            density: 'compact', showColumnFilters: false,
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' } }}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Options', //change header text
              size: 120, //make actions column wider
            },
          }}
          muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}


        />
      </div>
      {/************  Modal Select ******************/}

      {/* MODAL CUENTA */}
      <Modal
        open={openModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '50%', height: '70%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              // background: 'primary',
              // border: 'none',
              // cursor: 'pointer',
            }}
            className='btn btn-outline-danger btn-sm'
            onClick={cerrarModalPer}
          >
            <i className='fa fa-x'></i>
          </button>

          <TablaCuenta selecionaFila={selecionaFila} />
        </Box>
      </Modal>

      {/* MODAL PERSONA */}
      <Modal
        open={openModalPersona}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: '60%', height: '90%', overflow: 'auto' }}>
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              // background: 'primary',
              // border: 'none',
              // cursor: 'pointer',
            }}
            className='btn btn-outline-danger btn-sm'
            onClick={cerrarModalPersona}
          >
            <i className='fa fa-x'></i>
          </button>

          < TablaPersona selectFila={selectFilaPersona} />

        </Box>
      </Modal>
    </>
  )
}


/* REPORTES DE ADMINSTRAICON  */


export const Report6 = (prosp) =>{
  const {dataTipo} = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [pending,        setPending] = useState(false);
  const [emp_sl_t_suc,   emp_setSl_t_suc] = useState('');
  const [text_fechaD,    setText_fechaD] = useState(new Date());
  const [text_fechaH,    setText_fechaH] = useState(new Date());



  const emp_changeSl_t_suc = (event) => { 
    emp_setSl_t_suc(event.target.value);
    $("#emp_slc_tem").focus();
  }
  /** Primer reporte */
  const columnsReport = useMemo( () => [ 
    { header: 'IDConsolidado', accessorKey: 'IDConsolidado' },
    { header: 'NumCp', accessorKey: 'NumCp' },
    { header: 'Credito', accessorKey: 'Credito' },
    { header: 'Fecha', accessorKey: 'Fecha' },
    { header: 'Placa', accessorKey: 'Placa' },
    { header: 'Chofer', accessorKey: 'Chofer' },
    { header: 'Sucursal', accessorKey: 'Sucursal' },
  ],[]);
  const [dataReport, setdataReport] = useState([]);

  const onClickListar=async()=>{
    const transFechaD = moment(text_fechaD).format('YYYYMMDD');
    const transFechaH = moment(text_fechaH).format('YYYYMMDD');
    setPending(true)

    const url_dataReport = `/apiRepFinanzasCreditos?`;
    const body = {
                  vTipoReport: dataTipo,
                  vcodEmpresa:   cook_idEmp,
                  sucursal:      emp_sl_t_suc=='9999'?'':emp_sl_t_suc,
                  fecha:         transFechaD,
                  fecha2:         transFechaH,
      };

    console.log("body : ", body);

    const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    const response = await fetch(url_dataReport, opPe);
    console.log("response::", response);
    // const url_lstClientes = `/apiReporFAC?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&vSucursal=${emp_sl_t_suc}&vfecD=${transFechaD}&vfecH=${transFechaH}`;
    // const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } };
    // const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    emp_setSl_t_suc([])
    setdataReport([])
    setPending(false)
    setText_fechaD(new Date())
    setText_fechaH(new Date())
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "RESUMEN DE CONSOLIDADOS CON DOCS AL CREDITO _"+moment(new Date()).format('YYYYMMDD HH:mm')

    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "REPORTE-RESUMEN DE CONSOLIDADOS CON DOCS AL CREDITO _"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-10">
          <div className="row ">
              <div className="col-lg-4" style={{ zIndex: '3' }}>
                <label className="form-label mb-0 " >Fecha Desde:</label>
                <DatePicker 
                  locale="es"
                  wrapperClassName='input_date'
                  className="form-control form-control-sm text-center"
                  selected={text_fechaD} 
                  onChange={date => setText_fechaD(date)} 
                  dateFormat="yyyy-MM-dd"
                />
              </div>
              <div className="col-lg-4" style={{ zIndex: '3' }}>
                <label className="form-label mb-0 " >Fecha Hasta:</label>
                <DatePicker 
                  locale="es"
                  wrapperClassName='input_date'
                  className="form-control form-control-sm text-center"
                  selected={text_fechaH} 
                  onChange={date => setText_fechaH(date)} 
                  dateFormat="yyyy-MM-dd"
                />
              </div>
              <div className="col-lg-4">
                <label className="form-label mb-0 " >Sucursal:</label>
                <SelectSucursal id="veh_slc_suc" value={emp_sl_t_suc} onChange={emp_changeSl_t_suc} />
              </div>
            </div>
        </div>
        <div className="col-lg-2 text-end">
        
            <Tooltip title="Consultar información" placement="top">
              <>
                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>
              </>
            </Tooltip>
            <Tooltip title="Exportar" placement="top">
              <div className="btn-group" role="group">
                <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                  <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                  <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                </ul>
              </div>
            </Tooltip>
            <AButton tp_tilde="Limpiar y consultar de nuevo" id="btn4" tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>
      
      <div className="card-datatable table-responsive ">
        <MaterialReactTable 
          columns={columnsReport}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{  
            density: 'compact', showColumnFilters: false,  
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' }}}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
              'mrt-row-actions': {
                header: 'Options', //change header text
                size: 120, //make actions column wider
              },
            }}
            muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}
          
        />
      </div>
    </>
  )
}

export const Report7 = (prosp) =>{
  const {dataTipo} = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [dataReport, setdataReport] = useState([]);

  const [pending,       setPending] = useState(false);
  const [text_fechaD,   setText_fechaD] = useState(new Date());
  const [text_fechaH,   setText_fechaH] = useState(new Date());
  const [slcVendedor,   setSlcVendedor] = useState([""]);
  const [slcCliente,    setSlcCliente] = useState([""]);
  const [slcArticulo,   setSlcArticulo] = useState([""]);
  const [slcProveedor,  setSlcProveedor] = useState([""]);
  const [slcNotaCred ,    setslcNotaCred] = useState('');

  const funSlcVendedor = async (inputValue) => {
    const url = '/apiVendedores?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }
  const funSlcCliente = async (inputValue) => {
    const url = '/apiClientes?vcodEmpresa=' + cook_idEmp + '&vtxtConsult=' + inputValue;
    const opPe = { method: "GET", headers: { 'Content-Type': 'application/json' } }
    const response = await fetch(url, opPe);
    const data = await response.json();
    return data;
  }
  const funDArticulos = useCallback( async (inputValue) => {
    const url ='/apiArticulos?vcodEmpresa='+cook_idEmp+'&vtxtConsult='+inputValue;
    const opPe = { method: "GET", headers: {'Content-Type': 'application/json'} }
    const response = await fetch(url,opPe);
    const data = await response.json();
    return data;
  },[]) 
  const funSlcProvedor = async (inputValue) => {
    const url = '/apiConsul'
    const body = { vcodEmpresa: cook_idEmp, vtxtConsult: inputValue };
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url, opPe);
    const data = await response.json();
    //console.log(data)
    //console.log('select ',data);
    return data;
  }
  const handleFieldChange = (fieldName) => (event) => {
    const value = event.target.value;
    setslcNotaCred(value);
  };
  // const handleSelectChangeV = (selectedOption) => { setSlcVendedor(selectedOption);  };
  // const handleSelectChangeC = (selectedOption) => { setSlcCliente(selectedOption);  };
  const handleSelectChange = (selectedOption) => { setSlcArticulo(selectedOption);  };

  // const emp_changeSl_t_suc = (event) => { 
  //   emp_setSl_t_suc(event.target.value);
  //   $("#emp_slc_tem").focus();
  // }
  /** Primer reporte */

  const columns  = useMemo( () => [
    { header: 'MES',               accessorKey: 'Mes'},
    { header: 'E. 1 V.CONTADO',          accessorKey: 'Ejercicio1_VtaCont' },
    { header: 'E. 1 V.CREDITO',           accessorKey: 'Ejercicio1_VtaCred' },
    { header: 'E. 2 V.CONTADO',           accessorKey: 'Ejercicio2_VtaCont' },
    { header: 'E. 2 V.CREDITO',            accessorKey: 'Ejercicio2_VtaCred' }

  ],[])
  const onClickListar=async()=>{
    setdataReport([])
    const transFechaD = moment(text_fechaD).format('YYYY');
    const transFechaH = moment(text_fechaH).format('YYYY');
    // let arrvVend = slcVendedor.map(option => option.Code)
    let arrvClie = slcCliente.Code?slcCliente.Code:"";
    if(arrvClie===""){
      notify_warning("Seleccione un cliente ");
      return
    }
    setPending(true)

    const body = {
      vTipoReport: dataTipo,
      vcodEmpresa: cook_idEmp,
      fecha: transFechaD,
      fecha2: transFechaH,
      codigoCliente: arrvClie,
      notaCredito: slcNotaCred?slcNotaCred:''
    };
    const url_lstClientes = `/apiRepFinanzasCreditos?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&vfecD=${transFechaD}&vfecH=${transFechaH}&arrvClie=${arrvClie}&notaCredito=${slcNotaCred}`;
    console.log("URL:: ", `/apiRepFinanzasCreditos?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&vfecD=${transFechaD}&vfecH=${transFechaH}&arrvClie=${arrvClie}&notaCredito=${slcNotaCred}`);
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    setText_fechaD(new Date())
    setText_fechaH(new Date())
    setSlcVendedor([])
    setSlcCliente([])
    setSlcArticulo([])
    setSlcProveedor([])
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "REPORTE_RESUMEN DE VENTAS MENSUAL CLIENTES_"+moment(new Date()).format('YYYYMMDD HH:mm')

    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "REPORTE_RESUMEN DE VENTAS MENSUAL - CLIENTES _"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  return (
    <>
      <div className="row mb-1">
        <div className="col-lg-10">
          <div className="row ">
            <div className="col-lg-2" style={{ zIndex: '5' }}>
              <label className="form-label mb-0 " >Fecha Desde:</label>
              <DatePicker 
                locale="es"
                wrapperClassName='input_date'
                className="form-control form-control-sm text-center"
                selected={text_fechaD} 
                onChange={date => setText_fechaD(date)} 
                showYearPicker // Mostrar solo el selector de año
                dateFormat="yyyy" // Formato de año
              />
            </div>
            <div className="col-lg-2" style={{ zIndex: '5' }}>
              <label className="form-label mb-0 " >Fecha Hasta:</label>
              <DatePicker 
                locale="es"
                wrapperClassName='input_date'
                className="form-control form-control-sm text-center"
                selected={text_fechaH} 
                onChange={date => setText_fechaH(date)} 
                showYearPicker // Mostrar solo el selector de año
                dateFormat="yyyy" // Formato de año
              />
            </div>
            <div className="col-lg-2" style={{ zIndex: '5' }}>
              <label className="form-label mb-0 " >Nota de Credito:</label>
              <select id="txtActi" name="txtActi" className="form-select form-select-sm" value={slcNotaCred} onChange={handleFieldChange()} >
                <option value={""} >{"<<Selecciona>>"}</option>
                <option value={"SI"} >Si</option>
                <option value={"NO"} >No</option>
              </select>
            </div>
            <div className="col-lg-6" style={{ zIndex: '4' }}>
              <label className="form-label mb-0" >Cliente:</label>
              <AsyncSelect 
                // isMulti
                //styles={styles}
                placeholder="Busque por Cod/Desc"
                className="sele_pru from-control form-control-sm  "  
                value={slcCliente}
                cacheOptions
                defaultOptions
                getOptionLabel={e => e.Code +' - '+ e.Name}
                getOptionValue={e => e.Code }
                loadOptions={funSlcCliente}
                onChange={setSlcCliente}
                //maxMenuHeight={150}
                // onChange={(e)=>{
                //   setSlcCliente(e)
                  // setTimeout(() =>{
                  // $('.css-wsp0cs-MultiValueGeneric').each(function() {
                  //     var textoCompleto = $(this).text();
                  //     var textoCorto = textoCompleto.split('-')[0].trim();
                  //     $(this).text(textoCorto);
                  // });
                  // },1000)
                // }}
              />
            </div>
          </div>
          
          
        </div>
        <div className="col-lg-2 text-end">
        
            <Tooltip title="Consultar información" placement="top">
              <>
                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>&nbsp;
              </>
            </Tooltip>
            <Tooltip title="Exportar" placement="top">
              <div className="btn-group" role="group">
                <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                  <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                  <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                </ul>
              </div>
            </Tooltip> 
            <AButton tp_tilde="Limpiar y consultar de nuevo"  tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>
      
      <div className="card-datatable table-responsive ">
        <MaterialReactTable 
          columns={columns}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{  
            density: 'compact', showColumnFilters: false,  
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' }}}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
              'mrt-row-actions': {
                header: 'Options', //change header text
                size: 120, //make actions column wider
              },
            }}
          
            muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}
        />
      </div>
    </>
  )
}

export const Report8 = (prosp) =>{
  const {dataTipo} = prosp

  const cookies = new Cookies();
  const cook_idEmp = cookies.get('dtEmp_id');

  const [dataReport, setdataReport] = useState([]);

  const [pending,       setPending] = useState(false);
  const [text_fechaD,   setText_fechaD] = useState(new Date());
  const [text_fechaH,   setText_fechaH] = useState(new Date());

  const columns = useMemo(() => [
    { header: 'Sucursal', accessorKey: 'Sucursal' },
    { header: 'Documento', accessorKey: 'Documento' },
    { header: 'Cliente', accessorKey: 'Cliente' },
    { header: 'FEmision', accessorKey: 'FEmision' },
    { header: 'FVencimiento', accessorKey: 'FVencimiento' },
    { header: 'Total', accessorKey: 'Total' },
    { header: 'Amortizacion', accessorKey: 'Amortizacion' },
    { header: 'Saldo', accessorKey: 'Saldo' },
    { header: 'Dias', accessorKey: 'Dias' },
    { header: 'CondVenta', accessorKey: 'CondVenta' },
    { header: 'ID_Agente', accessorKey: 'ID_Agente' },
    { header: 'FechaGuia', accessorKey: 'FechaGuia' },
    { header: 'Mesa', accessorKey: 'Mesa' },
    { header: 'Motivo No liquidacion', accessorKey: 'Motivo_No_liquidacion' },

  ], [])
  const onClickListar=async()=>{
    setdataReport([])
    const transFechaD = moment(text_fechaD).format('YYYYMMDD');
    const transFechaH = moment(text_fechaH).format('YYYYMMDD');
    setPending(true)

    const body = {
      vTipoReport: dataTipo,
      vcodEmpresa: cook_idEmp,
      fecha: transFechaD,
      fecha2: transFechaH,
    };
    const url_lstClientes = `/apiRepFinanzasCreditos?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&vfecD=${transFechaD}&vfecH=${transFechaH}`;
    // console.log("URL:: ", `/apiRepFinanzasCreditos?vcodEmpresa=${cook_idEmp}&vTipoReport=${dataTipo}&vfecD=${transFechaD}&vfecH=${transFechaH}`);
    const opPe = { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }
    const response = await fetch(url_lstClientes, opPe);
    const data = await response.json();
    setdataReport(data)
    setPending(false)
  };
  const onClickLimpiar = async () => {
    setdataReport([])
    setPending(false)
    setText_fechaD(new Date())
    setText_fechaH(new Date())
  }
  const onClickCSV = async () => {
    const fileType = "CSV"
    const nameFileType = "REPORTE_SALDOS PENDIENTES_"+moment(new Date()).format('YYYYMMDD HH:mm')

    const showData = async () => {
      if (dataReport.length !== 0 ) {
        for (var i = 0; i < dataReport.length; i++) {
          let fecha = new Date(moment(dataReport[i]["FechaEmision"]).format('YYYY-MM-DD HH:mm:ss'))
          fecha.setMinutes(fecha.getMinutes() + fecha.getTimezoneOffset())
          dataReport[i]["FechaEmision"] = fecha;
        };
        const ws = XLSX.utils.json_to_sheet(dataReport);
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"csv", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.CSV`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  const onClickExcel = async () => {
    const fileType = "xlsx"
    const nameFileType = "REPORTE_ SALDOS PENDIENTES"+moment(new Date()).format('YYYYMMDD HH:mm')
    const showData = async () => {
      if (dataReport.length !== 0 ) {
        const ws = XLSX.utils.json_to_sheet(dataReport);
        // Cabecera del archivo 
        // const header = []; // Cambia esto según tus necesidades
        // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
        const wb = {Sheets:{data:ws}, SheetNames:["data"]};
        const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
        const data = new Blob([excelBuffer], {type:fileType});
        FileSaver.saveAs(data, `${nameFileType}.xlsx`);
      }else{
        notify_warning("No existen datos, verificar campos")
      }
    }
    showData();
  }
  return (
    <>
      <div className="row mb-1">
        <div className="col-lg-10">
          <div className="row ">
            <div className="col-lg-3" style={{ zIndex: '5' }}>
              <label className="form-label mb-0 " >Fecha Desde:</label>
              <DatePicker 
                // locale="es"
                // wrapperClassName='input_date'
                // className="form-control form-control-sm text-center"
                // selected={text_fechaD} 
                // onChange={date => setText_fechaD(date)} 
                // showYearPicker // Mostrar solo el selector de año
                // dateFormat="yyyy-mm-dd" // Formato de año

                locale="es"
                wrapperClassName='input_date'
                className="form-control form-control-sm text-center"
                selected={text_fechaD} 
                onChange={date => setText_fechaD(date)} 
                dateFormat="yyyy-MM-dd"
              />
            </div>
            <div className="col-lg-3" style={{ zIndex: '5' }}>
              <label className="form-label mb-0 " >Fecha Hasta:</label>
              <DatePicker 
                locale="es"
                wrapperClassName='input_date'
                className="form-control form-control-sm text-center"
                selected={text_fechaH} 
                onChange={date => setText_fechaH(date)} 
                // showYearPicker // Mostrar solo el selector de año
                dateFormat="yyyy-MM-dd" // Formato de año
              />
            </div>
          </div>
          
          
        </div>
        <div className="col-lg-2 text-end">
        
            <Tooltip title="Consultar información" placement="top">
              <>
                <button className="btn btn-sm btn-label-youtube" id="btn_procesar" onClick={onClickListar} >
                  <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                </button>&nbsp;
              </>
            </Tooltip>
            <Tooltip title="Exportar" placement="top">
              <div className="btn-group" role="group">
                <button id="btn_procesar_excel" type="button" className="btn btn-sm btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                  <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="btn_procesar_excel">
                  <button className="dropdown-item bx-ms" onClick={onClickCSV}>  CSV  </button>
                  <button className="dropdown-item bx-ms" onClick={onClickExcel}> EXCEL  </button>
                </ul>
              </div>
            </Tooltip> 
            <AButton tp_tilde="Limpiar y consultar de nuevo"  tp_posit="right" bt_class="btn btn-label-dark btn-sm" bt_funct={onClickLimpiar} ic_class="bx bx-trash-alt bx-ms" vl_tpbtn={2} />
        </div>
      </div>
      
      <div className="card-datatable table-responsive ">
        <MaterialReactTable 
          columns={columns}
          data={dataReport}
          state={{ isLoading: pending }}
          initialState={{  
            density: 'compact', showColumnFilters: false,  
            showGlobalFilter: false,
            columnVisibility: { clave: false, vlSema: false, },
          }}
          enableGrouping
          enableStickyHeader enableColumnResizing
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          localization={MRT_Localization_ES}
          enablePagination={true}
          muiTableBodyCellProps={{ sx: { p: '2px 16px' }}}
          enableGlobalFilterModes
          rowVirtualizerProps={{ overscan: 150 }}
          enableRowVirtualization
          displayColumnDefOptions={{
              'mrt-row-actions': {
                header: 'Options', //change header text
                size: 120, //make actions column wider
              },
            }}
          
            muiCircularProgressProps={{ color: 'error' }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}
        />
      </div>
    </>
  )
}
const ReportFinanzasCostos = () => {  
  //const cookies = new Cookies();
  //const cook_idEmp = cookies.get('dtEmp_id');
  const [slTipoReport, setSlTipoReport] = useState('');

  const changeSlTipoReport = (event) => {
    setSlTipoReport(event.target.value);
  }

  return (
    <div>
      <h6 className="text-muted"> Finanzas y Creditos</h6>
      <div className="card card-action">
        <div className="card-body ">
          <div className="row mb-2">
            <div className="col-lg-10">
              <div className="row mb-2">
                <div className="col-lg-2"><label className="form-label mb-0 " >Tipo Reporte:</label></div>
                <div className="col-lg-10">
                  <select id="slc_t_con" className="form-select form-select-sm" value={slTipoReport} onChange={changeSlTipoReport} >
                    <option value={"9999"} >--Seleccione--</option>
                    
                    <option value={"1"} > FIN001 :  CUENTAS POR COBRAR Y PAGAR POR CLIENTE POR F. VENCIMIENTO</option>
                    {/* <option value={"2"} > FIN002 :  CUENTAS POR COBRAR Y PAGAR POR F.VENCIMIENTO</option> */}
                    {/* <option value={"3"} > FIN003 :  CUENTA CORRIENTE DE CLIENTE </option> */}
                    <option value={"4"} > FIN004 :  CUENTAS POR COBRAR POR VENDEDORES POR F. VENCIMIENTO </option>
                    {/* <option value={"5"} > FIN005 :  CUENTA CORRIENTE DE EMPLEADO </option> */}
                    {/* <option value={"6"} > FIN006 :  AB065	RESUMEN DE CONSOLIDADOS CON DOCS AL CREDITO  </option> */}
                    <option value={"7"} > FIAD007 :  RESUMEN DE VENTAS MENSUAL - CLIENTES  </option>
                    <option value={"8"} > FIAD008 :  SALDOS PENDIENTES  </option>
                    {/* B063 : RESUMEN DE VENTAS MENSUAL - CLIENTES */}


                    {/* <option value={"4"} > AB027 : IMPRESION GUIA REMISION • TRANSPORTISTA</option>
                    <option value={"5"} > DIS018 : ORDEN DE SALIDA-REPARTO </option>
                    <option value={"6"} > DISOOI : SALIDA DE PRODUCTOS PARA REPARTO POR CONSOLIDADO</option>
                    <option value={"7"} > AB058 : SALIDA DE PRODUCTOS PARA REPARTO POR FECHA </option>
                    <option value={"8"} > INP023 : DETALLE DE GUIA REMISION </option>
                    <option value={"9"} > ANALISIS DE EFECTIVIDAD - POR RUTAS (VER 2.0) (EXCEL) </option> */}
                  </select>
                </div>
              </div>
            </div>
          </div>
          {
            (slTipoReport === '1') && (
              <Report1
                dataTipo={slTipoReport}
              />
            )
          }
          {
            (slTipoReport === '2') && (
              <Report2
                dataTipo={slTipoReport}
              />
            )
          }
          {
            (slTipoReport === '3') && (
              <Report3
                dataTipo={slTipoReport}
              />
            )
          }
          {
            (slTipoReport === '4') && (
              <Report4
                dataTipo={slTipoReport}
              />
            )
          }
           {
            (slTipoReport === '5') && (
              <Report5
                dataTipo={slTipoReport}
              />
            )
          }
          {
            (slTipoReport === '6') && (
              <Report6
                dataTipo={slTipoReport}
              />
            )
          }
          {
            (slTipoReport === '7') && (
              <Report7
                dataTipo={slTipoReport}
              />
            )
          }
          {
            (slTipoReport === '8') && (
              <Report8
                dataTipo={slTipoReport}
              />
            )
          }

        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default ReportFinanzasCostos

